import React, { ReactNode } from "react"
import { useContext, useState } from "react";
import NotificationContext from "./NotificationContext";
import { _i_encrypt, _i_decrypt } from "utils/LocalCrypto";

type IEncrypted = {
  bytes: ArrayBufferLike,
  string: () => string,
  hex: () => string
}

type IDecrypted = IEncrypted;

interface IAuthContextInterface {
  prompt: (data: string) => void,
  decrypt: (secret: string) => Promise<IDecrypted | null>,
  encrypt: (data: any, password: string) => Promise<IEncrypted | null>,
  encrypted: string | null,
  promptCallback: any,
  promptOpen: boolean,
  afterAuth: any,
  setAfterAuth: any
}


const AuthContext = React.createContext<IAuthContextInterface>({
  prompt: () => { },
  decrypt: () => { return new Promise(() => { }) },
  encrypt: () => { return new Promise(() => { }) },
  encrypted: null,
  promptCallback: null,
  promptOpen: false,
  afterAuth: null,
  setAfterAuth: null
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const notify = useContext(NotificationContext)
  const [promptOpen, setPromptOpen] = useState(false)
  const [promptCallback, setPromptCallback] = useState(false)
  const [afterAuth, setAfterAuth] = useState(null)
  const [encrypted, setEncrypted] = useState(null as string | null)

  React.useEffect(() => {
    console.log(encrypted)
  }, [encrypted])

  const encrypt = (data: any, password: string): Promise<IEncrypted | null> => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await _i_encrypt(data, password))
      } catch (e) {
        console.error(e)
        reject(e);
        notify.setMessage('Unable to apply secret');
        notify.setSeverity('error');
        notify.setOpen(true)
      }
    })
  }

  const decrypt = (secret: string): Promise<IDecrypted | null> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (encrypted === null) {
          reject('No encrypted data')
        } else {
          console.log(encrypted)
          const decrypted = await _i_decrypt(encrypted, secret)
          console.log(decrypted)
          if (decrypted) {
            setPromptOpen(false)
          }
          resolve(decrypted)
        }

      } catch (e) {
        console.error(e)
        reject(e)
        notify.setMessage('Incorrect secret');
        notify.setSeverity('error');
        notify.setOpen(true)
      }
    })
  }

  const prompt = (data: string) => {
    setPromptOpen(true)
    setEncrypted(data)
  }

  return (<AuthContext.Provider value={{
    prompt,
    decrypt,
    encrypt,
    encrypted,
    promptCallback,
    promptOpen,
    afterAuth,
    setAfterAuth
  }}>{children} </AuthContext.Provider>)
};

export default AuthContext;

