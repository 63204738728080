import React from "react"
import ResponsiveDialog from "../ResponsiveDialog";
import { Grid, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, InputAdornment, IconButton } from "@mui/material";
import { useState } from "react";
import * as __ from 'lib384/dist/384.esm.js'
import { Refresh } from "@mui/icons-material";

export default function WalletSecretDialog(props: { open: boolean, onClose: (passphrase: string) => void }) {
  const [open, setOpen] = useState(props.open);
  const [passphrase, setPassphrase] = useState<null | string>(null);
  const [submitClick, setSubmitClick] = useState<boolean>(false);
  const [availablePhrases, setAvailablePhrases] = useState<string[]>([])

  React.useEffect(() => {
    setOpen(props.open)
    if (props.open) {
      refreshPhrases()
    }
  }, [props.open])

  const refreshPhrases = () => {
    let passphrasePromiseArray: Promise<string>[] = []
    //this while loop creates a proimise for each new passphrase using __.strongphrase.generate()
    while (passphrasePromiseArray.length < 5) {
      passphrasePromiseArray.push(__.strongphrase.generate())
    }
    //this Promise.all() waits for all the promises to resolve and then sets the passphrase state to the first resolved promise
    Promise.all(passphrasePromiseArray).then((values) => {
      setAvailablePhrases(values)
    })
  }

  const submit = async () => {
    setSubmitClick(true)
    if (passphrase === null) return
    props.onClose(passphrase);
  }

  const onClose = () => {
    if (!submitClick) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  const selectPassphrase = (e: SelectChangeEvent<string | null>) => {
    setPassphrase(e.target.value)
  }

  return (
    <ResponsiveDialog
      title={'Wallet Secret'}
      onClose={onClose}
      open={open}>
      <Grid container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">

        <Grid item xs={10} sx={{ pb: 2, pt: 2 }}>
          <FormControl sx={{ m: 0, width: '100%' }} size="small">
            <InputLabel id="passphrase-select-autowidth-label">Select Passphrase</InputLabel>
            <Select
              sx={{ minWidth: 200 }}
              labelId="passphrase-select-autowidth-label"
              id="passphrase-select-autowidth"
              value={passphrase}
              onChange={selectPassphrase}
              autoWidth
              label="Select Passphrase"
            >
              {availablePhrases.map((phrase, index) => {
                return (
                  <MenuItem key={index} value={phrase}>{phrase}</MenuItem>
                )
              })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={refreshPhrases} sx={{mt: 2}}>
            <Refresh />
          </IconButton>
        </Grid>
        <Button variant={'outlined'} onClick={submit}>Ok</Button>
      </Grid>
    </ResponsiveDialog>
  )

}