import React, { ReactNode } from "react"
import { useContext, useEffect } from "react";
import AuthContext from "./AuthContext";
import IndexedKV from "utils/IndexedKV";

interface IWalletContextInterface {
  wallet: typeof AuthContext | null,
  id: string | null,
  identity: any,
  setWallet: React.Dispatch<React.SetStateAction<any>>,
  destroy: () => void,
  loadFromFile: (file: any) => void
}


const WalletContext = React.createContext<IWalletContextInterface>({
  wallet: null,
  id: null,
  identity: null,
  setWallet: () => { },
  destroy: () => { },
  loadFromFile: () => { }
});

export const WalletProvider = ({ children }: { children: ReactNode }) => {
  const walletDb = new IndexedKV({ db: "data", table: "wallet" });
  const auth = useContext(AuthContext)
  const [wallet, setWallet] = React.useState<any>(null);
  const [identity, setIdentity] = React.useState<any>(null);
  const [id, setId] = React.useState<any>(null);

  useEffect(() => {
    loadWallet()
  }, [])

  useEffect(() => {
    if (auth.afterAuth) {
      onAuth(auth.afterAuth)
    }
  }, [auth.afterAuth])

  const loadWallet = async () => {
    walletDb.openCursor('_wallet', (w: any) => {
      if(w.length === 0) return
      console.log(w)
      auth.prompt(decodeURIComponent(w[0].value))
    })
  }

  const onAuth = (d: string) => {
    const wallet = JSON.parse(d)
    setIdentity(wallet.key)
    setId(wallet.channelId)
  }

  const loadFromFile = async (file: {channelId: string, key: string}) => {
    if (file.hasOwnProperty('_id') && file.hasOwnProperty('identity')) {
      setWallet(file)
      setIdentity(file.key)
      setId(file.channelId)
      walletDb.setItem(file.channelId + '_wallet', JSON.stringify(file))
    } else {
      alert('File format is incorrect')
    }

  }

  const destroy = async () => {
    await walletDb.removeItem(id + '_wallet');
    window.location.reload()
  }

  return (<WalletContext.Provider value={{
    wallet,
    id,
    identity,
    setWallet,
    destroy,
    loadFromFile
  }}>{children} </WalletContext.Provider>)
};

export default WalletContext;

