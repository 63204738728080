import React from "react"
import ResponsiveDialog from "../ResponsiveDialog";
import {
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Button,
  TextareaAutosize
} from "@mui/material";
import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import WalletContext from "../../contexts/WalletContext";



export default function DestroyWalletDialog(props: { open: boolean, onClose: (passphrase: string) => void }) {
  const [open, setOpen] = useState(props.open);
  const [text, setText] = useState('');
  const [submitClick, setSubmitClick] = useState(true);

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])


  const submit = async () => {
    setSubmitClick(true)
    const passphrase = text;
    setText('')
    props.onClose(passphrase);
  }

  const onClose = () => {
    if (!submitClick) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  return (
    <ResponsiveDialog
      title={'Destroy Wallet'}
      onClose={onClose}
      open={open}>
      <Grid style={{ width: 500 }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">
        <Grid item xs={12} sx={{ pb: 2, pt: 2 }}>
          <DestroyWalletStepper />
        </Grid>
        <Button variant={'outlined'} onClick={submit}>Cancel</Button>
      </Grid>
    </ResponsiveDialog>
  )

}

const steps = [
  {
    label: 'Warning',
    description: `Destroying your local wallet will make it impossible to recover your wallet without a backup. 
    Would you like to export your wallet now?`,
  },
  {
    label: 'Export Wallet',
    description:
      'export',
  },
  {
    label: 'Remove',
    description: `Click remove and your wallet will be removed from this browser!`,
  },
];

function DestroyWalletStepper() {
  const wallet = useContext(WalletContext)
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{ [key: string]: boolean }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDownload = async () => {
    await downloadFile();
    await downloadPhoto();
    handleComplete();
  };

  const downloadFile = () => {
    return new Promise((resolve, reject) => {
      try {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8, ' + encodeURIComponent(JSON.stringify(wallet.wallet, null, 2)));
        element.setAttribute('download', wallet.id + '.txt');
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        resolve(true)
      } catch (error) {
        console.error(error);
        reject(error)
      }
    })

  }

  const downloadPhoto = () => {
    return new Promise((resolve, reject) => {
      try {
        const canvas = document.getElementById('qr-canvas') as HTMLCanvasElement;
        let element = document.createElement('a');
        element.setAttribute('href', canvas.toDataURL("image/png"));
        element.setAttribute('download', wallet.id + '.png');
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        resolve(true)
      } catch (error) {
        console.error(error);
        reject(error)
      }
    })
  }

  const handleRemove = () => {
    wallet.destroy()
  }

  const handleComplete = () => {


    setCompleted(_completed => {
      const newCompleted: { [key: string]: boolean } = completed;
      newCompleted[activeStep] = true;
      return newCompleted;
    });
    handleNext();
  };

  const goToComplete = () => {
    setActiveStep(2);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} completed={completed[index]}>
            <StepLabel>
              {step.label}
            </StepLabel>
            <StepContent>
              {step.description !== 'export'
                ? <Typography>{step.description}</Typography>
                :
                <Grid xs={12} item>
                  <TextareaAutosize style={{
                    overflowX: 'auto',
                    width: '100%'
                  }} value={JSON.stringify(wallet.wallet, null, 2)} />
                </Grid>

              }

              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="outlined"
                    onClick={index === 1 ? handleDownload : index === steps.length - 1 ? handleRemove : handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === 1 ? 'download' : index === steps.length - 1 ? 'Remove' : 'Continue'}
                  </Button>

                  {index === 0 ?
                    <Button
                      sx={{ mt: 1, mr: 1 }}
                      variant={'outlined'}
                      onClick={goToComplete}>
                      No, destroy
                    </Button>
                    :
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  }

                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
