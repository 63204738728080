import * as React from 'react';
import { Grid, Button } from "@mui/material";
import WalletSecretDialog from "./Modal/WalletSecretDialog";
import { useContext, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import * as __ from 'lib384/dist/384.esm.js'
import { walletFromPassPhrase } from 'utils/Wallet';
import NotificationContext from "../contexts/NotificationContext";
import IndexedKV from 'utils/IndexedKV';


export default function Generator(props: { didClick: () => void }) {
  const walletKv = new IndexedKV({ db: "data", table: "wallet" });
  const auth = useContext(AuthContext)
  const notify = useContext(NotificationContext)
  const [channel_id, setChannelId] = useState('')
  const [open, setOpen] = useState(false);

  const parseQueryString = React.useCallback((queryString: string) => {
    // Remove the "?" at the beginning of the query string if present
    queryString = queryString.slice(queryString.indexOf('?') + 1);

    // Split the query string into individual key-value pairs
    var queryParams = queryString.split('&');

    // Create an object to store the key-value pairs
    const queryObj: { [key: string]: string } = {};

    // Loop through each key-value pair and populate the object
    for (var i = 0; i < queryParams.length; i++) {
      var pair = queryParams[i].split('=');
      var key = decodeURIComponent(pair[0]); // Decode the key
      var value = decodeURIComponent(pair[1]); // Decode the value
      queryObj[key] = value;
    }

    return queryObj;
  }, [])

  React.useEffect(() => {
    var queryString = window.location.search;
    console.log(queryString);
    const query = parseQueryString(queryString);
    console.log(query);
    setChannelId(query.deposit)
  }, [])

  const registerWallet = async (jwk: JsonWebKey, password: string ) => {
    fetch(process.env.REACT_APP_384_API + '/wallet/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        jwk: jwk,
      })
    }).then((response) => {
      return response.json()
    }).then(async (data) => {
      console.log(data)
      if (data.channelId && data.key) {
        const sb_config = {
          channel_server: 'https://channel.384co.workers.dev',
          channel_ws: 'wss://channel.384co.workers.dev',
          storage_server: 'https://storage.384co.workers.dev'
        };
        const channelEndpoint = new __.NewSB.ChannelEndpoint(sb_config, data.key, data.channelId)
        data.originalLimit = await channelEndpoint.api.getStorageLimit();
        console.log(data)
        auth.encrypt(JSON.stringify(data), password).then((e) => {
          if(e){
          console.log(e)
          walletKv.setItem(data.channelId + '_wallet', encodeURIComponent(e.string()))
          setOpen(false)
          auth.prompt(e.string())
          }else{
            notify.setMessage('something broke!');
            notify.setSeverity('error');
            notify.setOpen(true)
          }
        })
      }
    })
  }

  const generateWallet = async (password: string) => {
    // const randomStrongPhrase = await __.strongphrase.generate();
    const envSalt = JSON.parse(process.env.REACT_APP_SALT as string)
    console.log(envSalt)
    const salt = new Uint8Array(envSalt)
    const jwkFromStrongPhrase = await walletFromPassPhrase(salt, password)
    console.log("Generated JWK from strongphrase:", jwkFromStrongPhrase)
    registerWallet(jwkFromStrongPhrase, password)
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center">
      <WalletSecretDialog open={open} onClose={generateWallet} />
      <Button onClick={() => {
        setOpen(true)
      }} sx={{ m: 1 }} variant={'outlined'}>
        Generate Wallet
      </Button>
      <Button sx={{ m: 1 }} variant={'contained'} onClick={props.didClick}>
        Import Wallet
      </Button>
    </Grid>
  );
}

