import React, {ReactNode} from "react"
import IndexedKV from "utils/IndexedKV";

interface IFileBrowserContextInterface {
  files: Array<any>,
  setFiles: React.Dispatch<React.SetStateAction<Array<any>>>,
  showDir: boolean,
  setShowDir: React.Dispatch<React.SetStateAction<boolean>>,
  getFiles: any
}

const FileBrowserContext = React.createContext<IFileBrowserContextInterface>({
  files: [],
  setFiles: () => { },
  showDir: false,
  setShowDir: () => { },
  getFiles: () => { }
});

export const FileBrowserProvider = ({ children }: { children: ReactNode }) => {
  const filesKv = new IndexedKV({ db: "data", table: "files" });
  const [files, setFiles] = React.useState<IFileBrowserContextInterface["files"]>([]);
  const [showDir, setShowDir] = React.useState(false);

  const getFiles = () => {
    let files: IFileBrowserContextInterface["files"] = []
    filesKv.openCursor('.', (item: File) => {
      if(item?.name){
        files.push(item);
      }
    }).then(() => {
      setFiles(files)
    })
  }

  return (<FileBrowserContext.Provider value={{
    files,
    setFiles,
    showDir,
    setShowDir,
    getFiles
  }}>{children} </FileBrowserContext.Provider>)
};

export default FileBrowserContext;

