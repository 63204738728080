import * as React from 'react';
import { Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import EnhancedTable from "./TokenList";
import FileBrowserContext from "../contexts/FileBrowserContext";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PurchaseDialog from "./Modal/PurchaseDialog";
import IndexedKV from 'utils/IndexedKV';


export default function TokenExplorer() {
  const filesKv = new IndexedKV({ db: "data", table: "files" });
  const FileBrowser = React.useContext(FileBrowserContext)
  const [type, setType] = React.useState('vpn');
  const [openPurchase, setOpenPurchase] = React.useState(false);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setType(event.target.value);
  };

  React.useEffect(() => {
    FileBrowser.getFiles();
  }, [])

  const closeFileExplorer = () => {
    FileBrowser.setFiles([]);
    FileBrowser.setShowDir(false);
  }

  const onDelete = (toDelete: Array<string>) => {
    toDelete.forEach((item) => {
      filesKv.removeItem(item)
    })
    FileBrowser.getFiles();
  }

  const getToken = () => {
    setOpenPurchase(true)
  }


  return (
    <Grid container>
      <PurchaseDialog open={openPurchase} type={type} onClose={()=>{
        setOpenPurchase(false)
      }} />
      <Grid xs={12} md={6} item>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          sx={{ width: 150 }}
          label={'Select a token'}
          onChange={handleChange}
        >
          <MenuItem value={''}>Select...</MenuItem>
          <MenuItem value={'vpn'}>VPN</MenuItem>
          <MenuItem value={'storage'}>Storage</MenuItem>
        </Select>
        <IconButton onClick={getToken} aria-label="delete" size="large">
          <AddCircleOutlineIcon fontSize="inherit" />
        </IconButton>

      </Grid>
      <EnhancedTable rows={FileBrowser.files} onDelete={onDelete} />
    </Grid>
  );
}
