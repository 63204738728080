import React from "react"
import ResponsiveDialog from "../ResponsiveDialog";
import { Grid, TextField, Button } from "@mui/material";
import { useState } from "react";

const channel_id = 'k8VkoqI5s9swY90FyWkfSao0z0V8TdZseV2O9lCOBVK5zrv2BfAPKjETaeVa0v0A'

export default function FirstVisitDialog(props: { open: boolean, onClose: () => void }) {
  const [open, setOpen] = useState(props.open);
  const [text, setText] = useState(channel_id);
  const [submitClick, setSubmitClick] = useState(false);

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  const updateText = (e: React.BaseSyntheticEvent) => {
    setText(e.target.value)
  }

  const submit = async () => {
    setSubmitClick(true)
    // await window.Snackabra.connect(text)
    props.onClose();
  }

  const onClose = () => {
    if (!submitClick) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  return (
    <ResponsiveDialog
      title={'Change channel'}
      onClose={onClose}
      open={open}>
      <Grid style={{ width: 500 }}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">
        <Grid item xs={12} sx={{ pb: 2, pt: 2 }}>
          <TextField
            multiline
            id="channel-id"
            placeholder="Enter channel ID"
            value={text}
            fullWidth
            onChange={updateText}
          />
        </Grid>
        <Button variant={'outlined'} onClick={submit}>Ok</Button>
      </Grid>
    </ResponsiveDialog>
  )

}