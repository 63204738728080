import React, { useCallback, useContext, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import jsQR, { QRCode } from "jsqr-es6";
import Generator from "./Generator";
import WalletContext from "../contexts/WalletContext";
import ExportWallet from "./ExportWallet";
import AuthContext from "../contexts/AuthContext";
import { arrayBufferToBase64 } from "snackabra"

const baseStyle = {
  flex: 1,
  height: '100%',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function DropZone(props: { messages: any }) {
  const [success, setSuccess] = React.useState(false)
  const wallet = React.useContext(WalletContext)
  const auth = useContext(AuthContext)

  React.useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false)
      }, 5000)
    }

  }, [success])


  React.useEffect(() => {
    for (let x in props.messages) {
      if (props.messages[x].image !== '') {
        // document.Snackabra.storage.retrieveData()
      }
    }
  }, [props.messages])


  const readQrCode = (buffer: ArrayBufferLike): Promise<QRCode["data"]> => {
    return new Promise((resolve, reject) => {
      try {
        let img = new Image();
        img.src = "data:image/png;base64," + arrayBufferToBase64(buffer).trim();
        console.log(img.src)
        img.onload = () => {
          let imgWidth = img.width;
          let imgHeight = img.height;
          console.log(imgWidth, imgHeight)
          let c = document.createElement("canvas") as HTMLCanvasElement;
          c.width = imgWidth;
          c.height = imgHeight;
          let ctx = c.getContext("2d") as CanvasRenderingContext2D;
          ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
          let imageData = ctx.getImageData(0, 0, imgWidth, imgHeight);
          console.log(imageData)
          let code = jsQR(imageData.data, imgWidth, imgHeight);
          console.log(code)
          if (code) {
            resolve(code.data)
          } else {
            reject('No QR code found')
          }

        };
      } catch (e) {
        console.log(e)
        reject(e)
      }
    })
  }

  const parseBuffer = (type: string, buffer: ArrayBufferLike): Promise<ArrayBufferLike> => {
    return new Promise(async (res, rej) => {
      if (type.match(/^image/)) {
        const data = await readQrCode(buffer)
        const enc = new TextEncoder()
        res(enc.encode(data).buffer)
      } else if (type.match(/^text/)) {
        console.log("found a text file");
        res(buffer);
      }
    });
  }

  const processFiles = (acceptedFiles: Array<File>) => {

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = async () => {
        if (reader.result instanceof ArrayBuffer) {
          parseBuffer(file.type, reader.result).then((contents) => {
            try {
              const dec = new TextDecoder()
              const text = dec.decode(contents)
              console.log(text)
              auth.prompt(text.trim())
            } catch (e) {
              alert('File format is incorrect')
            }

          });
        }
      }
      reader.readAsArrayBuffer(file)
    })

  }

  //This is where we would want to do something with the files when they are uploaded
  //https://mozilla.github.io/pdf.js/examples/
  const onDrop = useCallback(processFiles, [])
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open
  } = useDropzone({
    onDrop,
    noDrag: !!wallet?.id,
    noClick: true,
    accept: {
      'image/*': ['.jpeg', '.png'],
      'text/*': ['.txt']
    }, maxFiles: 1
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <Grid {...getRootProps({ style })}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start">
      <input {...getInputProps()} />
      <Grid xs={12} item>
        <Typography variant={'h3'} align={'center'}>Welcome!</Typography>
      </Grid>
      {wallet.id ?
        <Grid xs={12} item>
          <Typography variant={'body1'} align={'center'}>
            Your wallet <strong>{wallet.id}</strong> is active
          </Typography>
        </Grid> :
        <Grid xs={12} item>
          <Typography variant={'body1'} align={'center'}>It looks like there is currently no wallet registered in this
            browser.</Typography>
          <Typography variant={'body1'} align={'center'}>Either generate a new wallet or import a wallet.</Typography>
        </Grid>

      }
      {wallet.id ?
        <ExportWallet onClick={console.log} />
        : <Generator didClick={open} />
      }

    </Grid>
  )

}

export default DropZone
