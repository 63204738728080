import React, { useContext, useState }  from "react"
import ResponsiveDialog from "../ResponsiveDialog";
import { Grid, TextField, Typography, Button } from "@mui/material";
import NotificationContext from "../../contexts/NotificationContext";
import WalletContext from "../../contexts/WalletContext";

const info: { [key: string]: string }  = {
  'vpn': 'Each unit is a year of VPN access and costs $20.00 USD',
  'storage': 'Each unit is a gigabyte (GB) and costs $0.20 USD',
}

export default function PurchaseDialog(props: { open: boolean, type: string, onClose: () => void }) {
  const notify = useContext(NotificationContext)
  const wallet = useContext(WalletContext)
  const [open, setOpen] = useState(props.open);
  const [quantity, setQuantity] = useState(props.type === 'vpn' ? 1 : 100);

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    setQuantity(props.type === 'vpn' ? 1 : 100)
  }, [props.type])

  const updateValue = (e: React.BaseSyntheticEvent) => {
    if (props.type === 'storage' && e.target.value >= 100) {
      setQuantity(e.target.value)
    }

    if (props.type === 'vpn' && e.target.value >= 1) {
      setQuantity(e.target.value)
    }
  }

  const submitIfEnter = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      submit();
    }
  }

  const submit = () => {
    try {
      fetch(process.env.REACT_APP_384_API + '/api/checkout', {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ type: props.type, quantity: quantity, wallet_id: wallet.id })
      }).then((r) => {
        if (r.ok) {
          return r.json()
        } else {
          throw new Error(r.statusText)
        }
      }).then((body) => {
        window.location.href = body.url
      })
    } catch (e) {
      console.error(e)
      notify.setMessage('An error occurred when submitting your request');
      notify.setSeverity('error');
      notify.setOpen(true)
    }

  }

  const onClose = () => {
    props.onClose()
  }

  return (
    <ResponsiveDialog
      title={'Purchase ' + props.type + ' Tokens'}
      onClose={onClose}
      open={open}>
      <Typography variant={'body1'}>
        Purchase the token quantity that you want and they will be delivered to your wallet.
      </Typography>
      <Grid style={{ width: 500 }}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
        <Grid item xs={12} md={6} sx={{ pb: 2, pt: 2 }}>
          <TextField
            type={"number"}
            id={'token-quantity'}
            placeholder="Token Quantity"
            value={quantity}
            fullWidth
            onKeyDownCapture={submitIfEnter}
            onChange={updateValue}
          />
        </Grid>
        <Grid item md={1} xs={0} />
        <Grid item xs={12} md={5}>
          <Typography variant={'body1'} sx={{ textAlign: 'center' }}>
            {info[props.type]}
          </Typography>
        </Grid>
      </Grid>
      <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start">
        <Button variant={'outlined'} onClick={submit}>Submit</Button>
        <Button variant={'outlined'} onClick={onClose}>Cancel</Button>
      </Grid>
    </ResponsiveDialog>
  )

}
