import { SBServer } from "snackabra";

if(!process.env.REACT_APP_CHANNEL_SERVER) {
  throw new Error("REACT_APP_CHANNEL_SERVER is not defined");
}

if(!process.env.REACT_APP_CHANNEL_SERVER_WS) {
  throw new Error("REACT_APP_CHANNEL_SERVER_WS is not defined");
}

if(!process.env.REACT_APP_STORAGE_SERVER) {
  throw new Error("REACT_APP_STORAGE_SERVER is not defined");
}

export const sb_config: SBServer = {
  channel_server: process.env.REACT_APP_CHANNEL_SERVER,
  channel_ws: process.env.REACT_APP_CHANNEL_SERVER_WS,
  storage_server: process.env.REACT_APP_STORAGE_SERVER,
};
