import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import PageNotFound from "./errors/PageNotFound";
import NavAppBar from "../components/NavAppBar";
import Home from "./Home";
import { SettingsProvider } from "../contexts/SettingsContext";
import { LogProvider } from "../contexts/LogContext";
import { WalletProvider } from "../contexts/WalletContext";
import { NotificationProvider } from "../contexts/NotificationContext";
import NotificationBar from "../components/NotificationBar";
import { AuthProvider } from "../contexts/AuthContext";


const AppRoutes = () => {
    return (
        <SettingsProvider>
            <LogProvider>
                <NotificationProvider>
                    <AuthProvider>
                        <WalletProvider>
                            <CssBaseline />
                            <Router>
                                <NavAppBar />
                                <Container sx={{ padding: '0px 24px' }}>
                                    <Routes>
                                        <Route path="/404" element={<PageNotFound />} />
                                        <Route path="/" element={<Home />} />
                                        <Route path="*" element={<PageNotFound />} />
                                    </Routes>
                                </Container>
                            </Router>
                        </WalletProvider>
                    </AuthProvider>
                    <NotificationBar />
                </NotificationProvider>
            </LogProvider>
        </SettingsProvider >
    );
};

export default AppRoutes;
