import * as React from 'react';
import { Grid, Button } from "@mui/material";
import DestroyWalletDialog from "./Modal/DestroyWalletDialog";
import { useContext, useEffect, useState } from "react";
import { QRCodeCanvas } from 'qrcode.react';
import WalletContext from "../contexts/WalletContext";
import IndexedKV from 'utils/IndexedKV';

export default function ExportWallet(props: { onClick: () => void }) {
  const wallet = useContext(WalletContext)
  const indexedKV = new IndexedKV({ db: "data", table: "wallet" });
  const [open, setOpen] = useState(false);
  const [encryptedWallet, setEncryptedWallet] = useState(null);

  useEffect(() => {
    const loadWallet = async () => {
      const loadedWallet = await indexedKV.getItem(wallet.id + '_wallet');
      setEncryptedWallet(loadedWallet)
    }
    loadWallet();
  }, [wallet.id])

  const destroyWallet = async (password: string) => {
    /*
    This is where we would put password generation and encrypt the wallet
     */
    // window.Snackabra.setIdentity().then(async () => {
    //   window.Snackabra.create(process.env.REACT_APP_CHANNEL_PASSWORD).then(() => {
    //     const wallet = JSON.stringify({
    //       identity: window.Snackabra.identity,
    //       _id: window.Snackabra.channel._id,
    //       challenge: password
    //     })
    //     window.walletDb.setItem(window.Snackabra.channel._id + '_wallet', wallet)
    //   })
    // })
    setOpen(false)
  }


  const exportWalletFiles = async () => {
    await downloadFile();
    await downloadPhoto();
    props.onClick();
  }

  const downloadFile = () => {
    return new Promise((resolve, reject) => {
      try {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8, ' + encodeURIComponent(JSON.stringify(wallet.wallet, null, 2)));
        element.setAttribute('download', wallet.id + '.txt');
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        resolve(true)
      } catch (error) {
        console.error(error);
        reject(error)
      }
    })

  }

  const downloadPhoto = () => {
    return new Promise((resolve, reject) => {
      try {
        const canvas = document.getElementById('qr-canvas') as HTMLCanvasElement;
        let element = document.createElement('a');
        if (canvas) {
          element.setAttribute('href', canvas.toDataURL("image/png"));
          element.setAttribute('download', wallet.id + '.png');
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          resolve(true)
        } else {
          reject('Canvas not found')
        }
      } catch (error) {
        console.error(error);
        reject(error)
      }
    })
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center">
      <QRCodeCanvas size={512} id={'qr-canvas'} hidden value={JSON.stringify(wallet.wallet)} />
      <DestroyWalletDialog open={open} onClose={destroyWallet} />
      <Button onClick={() => {
        setOpen(true)
      }} sx={{ m: 1 }} variant={'outlined'}>
        Destroy Wallet
      </Button>
      <Button sx={{ m: 1 }} variant={'contained'} onClick={exportWalletFiles}>
        Export Wallet
      </Button>
    </Grid>
  );
}

