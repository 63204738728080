import React, { useState, useEffect, useRef } from "react";
import * as __ from "lib384/dist/384.esm.js"
import IndexedKV from "../utils/IndexedKV";


export interface SettingsContextType {
  mode: "light" | "dark" | "system" | null;
  profile: any | null;
  key: string | null;
  setMode: (mode: "light" | "dark" | "system") => void;
  messageBubbles: "bubbles" | "stacked" | null;
  messageAlignment: "leftAligned" | "staggered" | null;
  updateMessageBubbles: (value: "bubbles" | "stacked") => void;
  updateMessageAlignment: (value: "leftAligned" | "staggered") => void;
  updateMode: (mode: "light" | "dark" | "system") => void;
}

const SettingsContext = React.createContext<SettingsContextType>({
  mode: null as SettingsContextType["mode"],
  profile: null as SettingsContextType["profile"],
  key: null as SettingsContextType["key"],
  setMode: () => {},
  messageBubbles: null as SettingsContextType["messageBubbles"],
  messageAlignment:
    null as SettingsContextType["messageAlignment"],
  updateMessageBubbles: () => {},
  updateMessageAlignment: () => {},
  updateMode: () => {},
});

export function SettingsProvider({ children }: { children: React.ReactNode }) {

  const kv = new IndexedKV({ db: "settings", table: "local" });
  const userKv = new IndexedKV({ db: "settings", table: "user" });
  let loading = useRef(true);

  const [mode, setMode] = useState<SettingsContextType['mode']>(null);
  const [messageBubbles, setMessageBubbles] = useState<SettingsContextType['messageBubbles']>(null);
  const [messageAlignment, setMessageAlignment] = useState<SettingsContextType['messageAlignment']>(null);
  const [profile, setProfile] = useState<SettingsContextType['profile']>(null);
  const [key, setKey] = useState<SettingsContextType['key']>(null);

  useEffect(() => {
    getPeristent();
  }, []);

  const getPeristent = async () => {
    const mode = await kv.getItem("settings-mode");
    const key = await userKv.getItem("key");
    const profile = await userKv.getItem("profile");
    if (key) {
      setKey(key);
    }
    console.log(profile)
    if (profile) {
      setProfile(profile);
    }else{
      createProfile();
    }

    if (mode) {
      setMode(mode);
    }
    const mb = await kv.getItem("messageBubbles");
    if (mb) {
      setMessageBubbles(mb);
    }
    const ma = await kv.getItem("messageAlignment");
    if (ma) {
      setMessageAlignment(ma);
    }
    loading.current = false;
  };

  const updateMessageBubbles = React.useCallback(
    (value: SettingsContextType["messageBubbles"]) => {
      if (value === null) return;
      setMessageBubbles(value);
      kv.setItem("messageBubbles", value);
    },
    [setMessageBubbles]
  );

  const updateMessageAlignment = React.useCallback(
    (value: SettingsContextType["messageAlignment"]) => {
      if (value === null) return;
      setMessageAlignment(value);
      kv.setItem("messageAlignment", value);
    },
    [setMessageAlignment]
  );

  const updateMode = React.useCallback(
    (mode: SettingsContextType["mode"]) => {
      if (mode === null) return;
      setMode(mode);
      kv.setItem("settings-mode", mode);
    },
    [setMode]
  );

  const createProfile = async () => {
    const key = new __.NewSB.SB384();
    console.log(key)
    debugger
    // const profile = {
    //   displayName: "Matt",
    //   userName: "matt384",
    //   email: "matt@384.co",
    //   avatar: "https://avatars.githubusercontent.com/u/1004709?v=4",
    //   firstName: "Matt",
    //   lastName: "Gagliardo",
    //   jobTitle: "Software Engineer",
    //   jobDesciption: "Software Engineer",
    //   jobType: "Full Time",
    //   phone: "555-555-5555",
    //   bio: "Software Engineer",
    //   createdAt: new Date().toISOString(),
    //   active: true,
    //   status: "Active",
    // }
  }


  return (
    <SettingsContext.Provider
      value={{
        mode,
        profile,
        key,
        setMode,
        messageBubbles,
        updateMessageBubbles,
        messageAlignment,
        updateMessageAlignment,
        updateMode,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsContext;
