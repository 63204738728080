import React from "react"
import ResponsiveDialog from "../ResponsiveDialog";
import { Grid, TextField, Button } from "@mui/material";
import { useContext, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import IndexedKV from "utils/IndexedKV";

export default function UnlockDialog(props: { open: boolean, onClose: () => void }) {
  const indexedKV = new IndexedKV({ db: "data", table: "wallet" });
  const auth = useContext(AuthContext)
  const [open, setOpen] = useState(props.open);
  const [text, setText] = useState('');
  const [errored, setErrored] = useState(false);
  const [submitClick, setSubmitClick] = useState(false);

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  const updateText = (e: React.BaseSyntheticEvent) => {
    setText(e.target.value)
  }

  const submitIfEnter = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      submit();
    }
  }

  const submit = async () => {
    try{
      setSubmitClick(true)
      console.log(text)// batter native culprit
      const decrypted = await auth.decrypt(text)
      if(decrypted){
        console.log(decrypted)
        const wallet = decrypted.string();
        console.log(wallet)
        // indexedKV.setItem(JSON.parse(wallet)._id + '_wallet', encodeURIComponent(auth.encrypted as unknown as string))
        auth.setAfterAuth(wallet)
        setText('')
      }
    }catch{
      setErrored(true)
      setText('')
    }

  }

  const onClose = () => {
    if (!submitClick) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  return (
    <ResponsiveDialog
      title={'Unlock Wallet'}
      onClose={onClose}
      open={open}>
      <Grid container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">
        <Grid item xs={12} sx={{ pb: 2, pt: 2 }}>
          <TextField
            type={"text"}
            id="wallet-secret-unlock"
            placeholder="Enter a wallet secret"
            value={text}
            error={errored}
            fullWidth
            onKeyDownCapture={submitIfEnter}
            onChange={updateText}
          />
        </Grid>
        <Button variant={'outlined'} onClick={submit}>Ok</Button>
      </Grid>
    </ResponsiveDialog>
  )

}
