// (c) 2023, 384 (tm)

let DEBUG = true;
if (DEBUG) {
    console.warn("WARNING - running in DEBUG mode, disable for production")
}
if (DEBUG) console.log("==== WALLET.js ====")
// @ts-ignore
if (!elliptic.ec) {
    // rely on elliptic having been loaded, presumably downloaded from:
    // https://github.com/indutny/elliptic/blob/master/dist/elliptic.min.js
    throw new Error('Module "elliptic" loaded, but elliptic.ec is not defined');
}
// @ts-ignore
const ec = new elliptic.ec('p384'); // Use P-384 curve

// for testing/evaluation, 100000 iterations is fine
// final version we do 10M iterations... IMPORTANT that production is 10M
const iterations = 100000;
console.log("We are doing " + iterations + " iterations")
if (iterations < 10000000) {
    console.warn("WARNING WARNING - using LESS THAN 10M ITERATIONS - test/dev only!")
}

// just for local testing, not really used
function generateWallet() {
    const key = ec.genKeyPair();
    const publicKey = key.getPublic('hex');
    const privateKey = key.getPrivate('hex');
    return { publicKey, privateKey };
}

//
// this is the main function, it takes a salt and a passphrase and returns a JWK
// the key can be used for ECDH (deriveKey) and ECDSA (sign).
// the salt is a Uint8Array, the passphrase is any string
// for the same salt and passphrase, the same key will be generated
//
export async function walletFromPassPhrase(salt: Uint8Array, passphrase: string | undefined) {
    if (DEBUG) {
        console.log("==== walletFromPassPhrase ====")
        console.log("salt: " + salt)
        console.log("passphrase: " + passphrase)
    }
    // local helpers, we want this file (wallet.js) to be self-contained
    function hexToBase64(hexString: { match: (arg0: RegExp) => any[]; }) {
        const byteArray = new Uint8Array(hexString.match(/.{1,2}/g).map((byte: string) => parseInt(byte, 16)));
        return btoa(String.fromCharCode.apply(null, byteArray as unknown as number[]));
    }
    function hexToBase64Url(hexString: any) {
        let base64 = hexToBase64(hexString);
        let base64url = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return base64url;
    }

    const hash = "SHA-384";
    const derivedKeyLength = 384 / 8; // For P-384 curve, in bytes
    const passphraseBuffer = new TextEncoder().encode(passphrase);
    const keyMaterial = await crypto.subtle.importKey(
        "raw",
        passphraseBuffer,
        { name: "PBKDF2" },
        false,
        ["deriveKey", "deriveBits"]
    );
    const derivedKey = await crypto.subtle.deriveKey(
        {
            name: "PBKDF2",
            salt: salt as unknown as ArrayBuffer,
            iterations: iterations,
            hash: hash
        },
        keyMaterial,
        {
            name: "HMAC",
            hash: hash,
            length: derivedKeyLength * 8
        },
        true,
        ["sign"]
    );

    // Convert the derived key to hexadecimal format for ec
    const derivedKeyArrayBuffer = await crypto.subtle.exportKey("raw", derivedKey);
    const byteArray = new Uint8Array(derivedKeyArrayBuffer);
    const fixedPrivateKeyHex = Array.from(byteArray).map(byte => byte.toString(16).padStart(2, '0')).join('');
    if (DEBUG) console.log("Derived key: " + fixedPrivateKeyHex);

    // Generate key pair using the d private key
    const keyPair = ec.keyFromPrivate(fixedPrivateKeyHex, 'hex');
    const publicKey = keyPair.getPublic();
    const privateKey = keyPair.getPrivate();

    // Convert key pair to JWK format
    const jwk = {
        kty: "EC",
        crv: "P-384",
        x: hexToBase64Url(publicKey.getX().toString('hex')),
        y: hexToBase64Url(publicKey.getY().toString('hex')),
        d: hexToBase64Url(privateKey.toString('hex')),
        ext: true,
        key_ops: ["deriveKey"] // For ECDH
    };
    
    if (DEBUG) console.log("Final result in JWK format:", jwk)

    if (DEBUG) console.log("VERIFICATION: Will try to import key into crypto.subtle")

    // we verify that the key can be imported into crypto.subtle properly
    const importedKey = await crypto.subtle.importKey("jwk", jwk, {
        name: "ECDH",
        namedCurve: "P-384"
    }, true, ["deriveKey"]).then((key) => {
        if (DEBUG) {
            console.log("Imported test key successfully! Looks good")
            console.log(key)
        }
        // return key
    }).catch((err) => {
        const msg = "Failed to import test key: " + err;
        console.error(msg)
        throw new Error(msg)
    });

    if (DEBUG) console.log("Final result in subtle key format: ", importedKey)
    return jwk;
}
