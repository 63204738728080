import React from 'react';
import { AppBar, Box, Grid, Toolbar } from "@mui/material";
import FirstVisitDialog from "./Modal/FirstVisitDialog";



export default function NavAppBar() {

  const [open, setOpenDialog] = React.useState(false)
  return (
    <Box sx={{ flexGrow: 1 }}>
      <FirstVisitDialog open={open} onClose={() => {
        setOpenDialog(false)
      }} />
      <AppBar position="fixed" sx={{ backgroundColor: 'black', textTransform: 'none' }}>
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}


